import React from 'react';

const FootballField = ({ homeLineup, awayLineup, homeFormation, awayFormation }) => {
  // Combine both home and away players into one object of positions
  const positions = {};

  // Map home team players to positions
  if (homeLineup && homeLineup.startXI) {
    homeLineup.startXI.forEach(({ player }) => {
      positions[player.grid] = {
        name: `${player.name} (${player.pos})`,
        team: 'home'
      };
    });
  }

  // Map away team players to positions
  if (awayLineup && awayLineup.startXI) {
    awayLineup.startXI.forEach(({ player }) => {
      positions[player.grid] = {
        name: `${player.name} (${player.pos})`,
        team: 'away'
      };
    });
  }

  // Helper function to render players in each column based on the formation
  const renderPlayersInColumn = (columnIndex, playersCount, team) => {
    const players = [];
    for (let i = 1; i <= playersCount; i++) {
      const pos = `${i}:${columnIndex + 1}`;
      const player = positions[pos];
      players.push(
        <div
          key={pos}
          className={`player ${player?.team || ''}`} /* Adds 'home' or 'away' class */
        >
          {player ? player.name : `P${pos}`} {/* Render player if available, otherwise placeholder */}
        </div>
      );
    }
    return players;
  };

  // Function to render the field based on the home and away formations
  const renderField = () => {
    if (!homeFormation || !awayFormation) return null;

    // Parse the formations (both home and away)
    const homeColumns = homeFormation.split('-').map(Number);
    const awayColumns = awayFormation.split('-').map(Number);

    // Ensure both formations have the same number of columns
    const maxColumns = Math.max(homeColumns.length, awayColumns.length);

    return (
      <div className="football-field">
        {Array.from({ length: maxColumns }).map((_, columnIndex) => {
          const homePlayersCount = homeColumns[columnIndex] || 0;
          const awayPlayersCount = awayColumns[columnIndex] || 0;

          return (
            <div key={columnIndex} className="column">
              {/* Render Home players in the current column */}
              {renderPlayersInColumn(columnIndex, homePlayersCount, 'home')}
              {/* Render Away players in the current column */}
              {renderPlayersInColumn(columnIndex, awayPlayersCount, 'away')}
            </div>
          );
        })}
      </div>
    );
  };

  return (
    <div className="football-formation">
      <h1>Football Field (Vertical Layout)</h1>
      {renderField()}
    </div>
  );
};

export default FootballField;

import React from "react";
import {useNavigate} from "react-router-dom";

function Header(){
    
    const navigate = useNavigate();

    function handleClick(){
        navigate("/");
    }

    return(
       <div>

        <div className="header">
            
            <div></div>
            <div onClick={handleClick}>NetScore</div>
            <div onClick={handleClick}>Home</div>
            <div></div>

        </div>

        <hr className="line"/>

       </div>
    )
}

export default Header;